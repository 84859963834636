import React from 'react';
import { StoreContextProvider } from './src/lib/context';

export const wrapRootElement = ({ element }) => (
  <StoreContextProvider>{element}</StoreContextProvider>
);

export const shouldUpdateScroll = ({ routerProps: { location }, getSavedScrollPosition }) => {
  const currentPosition = getSavedScrollPosition(location);

  const delay = 0;

  if (currentPosition) {
    const { isFilter } = location.state || {};
    if (!isFilter) {
      setTimeout(() => {
        window.scrollTo(...(currentPosition || [0, 0]));
      }, delay);
    }
  } else {
    window.scrollTo([0, 0]);
  }

  return false;
};
