import React, { useState, useEffect, useContext } from "react";

import { variantGidToId, variantIdToGid } from "@lib/helpers";
import client from "@lib/shopify";
import { sanityClient } from "@lib/sanity";

const SHOPIFY_CHECKOUT_STORAGE_KEY = "shopify_checkout_id";

const fetchUpsellProducts = async (ids) => {
  const upsells = await sanityClient.fetch(`
  *[_type == "productVariant" && variantID in [${ids}]]{
    productID,
    "upsells": *[_type == "product" && productID == ^.productID][0].upsells[]->{
       _id,
            title,
            price,
            comparePrice,
            isSpecial,
            specialText,
            productDetail,
            slug,
            bgColor{
              hex
            },
            bottleShot,
            productID,
            inStock,
            "variants": *[_type == "productVariant" && productID == ^.productID]{
              "id": variantID}
      
   },
  }
  `);
  return upsells;
};

const initialStoreState = {
  client,
  isAdding: false,
  isUpdating: false,
  cartIsOpen: false,
  nextPage: null,
  checkout: { lineItems: [] },
  upsellProducts: [],
};

const StoreContext = React.createContext({
  store: initialStoreState,
  setStore: () => null,
});

function createNewCheckout(store) {
  if (!store) {
    console.error("No store passed");
    return null;
  }
  return store.checkout.create();
}

function fetchCheckout(store, id) {
  return store.client.checkout.fetch(id);
}

async function setCheckoutInState(checkout, setStore, openCart) {
  // If there is no checkout bail out
  if (!checkout) return null;
  const isBrowser = typeof window !== "undefined";
  if (isBrowser) {
    localStorage.setItem(SHOPIFY_CHECKOUT_STORAGE_KEY, checkout.id);
  }

  const lineIds = checkout.lineItems.map((lineItem) =>
    variantGidToId(lineItem.variant.id)
  );

  const upsellProducts = await fetchUpsellProducts(lineIds);

  // Grab our product IDS
  const prodIds = await Promise.all(
    upsellProducts.map(async ({ productID }) => productID)
  );

  // Flatten and Dedup our upsells
  const filteredUpsells = upsellProducts
    .filter((item) => item.upsells)
    .map((series) =>
      series.upsells.filter((item) => item && !prodIds.includes(item.productID))
    )
    .reduce((acc, val) => acc.concat(val), []);

  setStore((prevState) => {
    return {
      ...prevState,
      isAdding: false,
      isUpdating: false,
      cartIsOpen: openCart ? true : prevState.cartIsOpen,
      checkout,
      upsellProducts: filteredUpsells,
    };
  });
}

const StoreContextProvider = ({ children }) => {
  const [store, setStore] = useState(initialStoreState);
  const [initStore, setInitStore] = useState(false);

  useEffect(() => {
    if (initStore === false) {
      const initializeCheckout = async () => {
        // Check for an existing cart.
        const isBrowser = typeof window !== "undefined";
        const existingCheckoutId = isBrowser
          ? localStorage.getItem(SHOPIFY_CHECKOUT_STORAGE_KEY)
          : null;

        if (existingCheckoutId) {
          try {
            const checkout = await fetchCheckout(store, existingCheckoutId);
            if (!checkout.completedAt) {
              setCheckoutInState(checkout, setStore);
              return;
            }
          } catch (e) {
            localStorage.setItem(SHOPIFY_CHECKOUT_STORAGE_KEY, null);
          }
        }

        const newCheckout = await createNewCheckout(client);
        setCheckoutInState(newCheckout, setStore);
      };

      initializeCheckout();
      setInitStore(true);
    }
  }, [store, initStore]);

  return (
    <StoreContext.Provider
      value={{
        store,
        setStore,
      }}
    >
      {children}
    </StoreContext.Provider>
  );
};

function useStore() {
  const { store } = useContext(StoreContext);
  return store;
}

function useCartCount() {
  const {
    store: { checkout },
  } = useContext(StoreContext);

  const count = checkout.lineItems.reduce(
    (runningTotal, item) => item.quantity + runningTotal,
    0
  );

  return count;
}

function useNextPage() {
  const {
    store: { nextPage },
    setStore,
  } = useContext(StoreContext);

  async function updateNextPage(value) {
    setStore((prevState) => {
      return { ...prevState, nextPage: value };
    });
  }

  return { updateNextPage, nextPage };
}

function useCartTotals() {
  const {
    store: { checkout },
  } = useContext(StoreContext);

  const tax = checkout.totalTaxV2
    ? `$${Number(checkout.totalTaxV2.amount).toFixed(2)}`
    : "-";
  const total = checkout.totalPriceV2
    ? `$${Number(checkout.totalPriceV2.amount).toFixed(2)}`
    : "-";
  const rawTotal = checkout.totalPriceV2
    ? Number(checkout.totalPriceV2.amount).toFixed(2)
    : 0;

  return {
    tax,
    total,
    rawTotal,
  };
}

function useCartItems() {
  const {
    store: { checkout },
  } = useContext(StoreContext);

  return checkout.lineItems;
}

function useUpsells() {
  const {
    store: { upsellProducts },
  } = useContext(StoreContext);
  return upsellProducts;
}

function useAddItemToCart() {
  const {
    store: { checkout, client },
    setStore,
  } = useContext(StoreContext);

  async function addItemToCart(variantId, quantity) {
    if (variantId === "" || !quantity) {
      console.error("Both a size and quantity are required.");
      return;
    }
    // Set Loading
    setStore((prevState) => {
      return { ...prevState, isAdding: true };
    });

    // Build the cart line item
    const newItem = {
      variantId: variantIdToGid(variantId),
      quantity: quantity,
    };

    const checkoutId = checkout.id;

    const newCheckout = await client.checkout.addLineItems(checkoutId, newItem);

    setCheckoutInState(newCheckout, setStore, true);
  }

  return addItemToCart;
}

function useAddItemsToCart() {
  const {
    store: { checkout, client },
    setStore,
  } = useContext(StoreContext);

  async function addItemsToCart(variants, discountCode = null) {
    if (!variants) {
      console.error("Both a size and quantity are required.");
      return;
    }
    // Set Loading
    setStore((prevState) => {
      return { ...prevState, isAdding: true };
    });

    const newItems = [];

    await variants.map((item) => {
      // Build the cart line item
      const newItem = {
        variantId: variantIdToGid(item.id),
        quantity: item.qty,
      };

      newItems.push(newItem);
    });

    const checkoutId = checkout.id;

    await client.checkout.addLineItems(checkoutId, newItems);
    if (discountCode) {
      await client.checkout.addDiscount(checkoutId, discountCode);
    }
    const newCheckout = await client.checkout.fetch(checkoutId);

    setCheckoutInState(newCheckout, setStore, true);
  }

  return addItemsToCart;
}

function useRemoveItemFromCart() {
  const {
    store: { client, checkout },
    setStore,
  } = useContext(StoreContext);

  async function removeItemFromCart(itemId) {
    // Set Loading
    setStore((prevState) => {
      return { ...prevState, isAdding: true };
    });
    const newCheckout = await client.checkout.removeLineItems(checkout.id, [
      itemId,
    ]);

    setCheckoutInState(newCheckout, setStore, true);
  }

  return removeItemFromCart;
}

function useUpdateItemsFromCart() {
  const {
    store: { checkout },
    setStore,
  } = useContext(StoreContext);

  async function updateItemsFromCart(items) {
    items = [].concat(items);

    // Set Loading
    setStore((prevState) => {
      return { ...prevState, isAdding: true };
    });
    const newCheckout = await client.checkout.updateLineItems(
      checkout.id,
      items
    );

    setCheckoutInState(newCheckout, setStore, true);
  }

  return updateItemsFromCart;
}

function useCheckout() {
  const {
    store: { checkout },
  } = useContext(StoreContext);

  return () => {
    window.open(checkout.webUrl, "_self");
  };
}

function useToggleCart() {
  const {
    store: { cartIsOpen },
    setStore,
  } = useContext(StoreContext);

  async function toggleCart() {
    setStore((prevState) => {
      return { ...prevState, cartIsOpen: !cartIsOpen };
    });
  }

  return toggleCart;
}
function useCloseCart() {
  const { setStore } = useContext(StoreContext);

  async function toggleCart() {
    setStore((prevState) => {
      return { ...prevState, cartIsOpen: false };
    });
  }

  return toggleCart;
}

function useDiscount() {
  const {
    store: { checkout, client },
    setStore,
  } = useContext(StoreContext);

  async function addDiscount(discountCode) {
    const checkoutId = checkout.id;
    const newCheckout = await client.checkout.addDiscount(
      checkoutId,
      discountCode
    );

    setCheckoutInState(newCheckout, setStore, true);
  }
  return { addDiscount };
}

export {
  client,
  StoreContextProvider,
  useAddItemToCart,
  useStore,
  useCartCount,
  useCartItems,
  useCartTotals,
  useRemoveItemFromCart,
  useUpdateItemsFromCart,
  useCheckout,
  useToggleCart,
  useCloseCart,
  useNextPage,
  useAddItemsToCart,
  useDiscount,
  useUpsells,
};
