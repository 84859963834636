import createSanityClient from "@sanity/client";
import sanityImage from "@sanity/image-url";

const options = {
  dataset: process.env.GATSBY_SANITY_PROJECT_DATASET || "production",
  projectId: process.env.GATSBY_SANITY_PROJECT_ID || "e2329nam",
  useCdn: process.env.NODE_ENV === "production",
  apiVersion: "2021-03-25",
};

export const sanityClient = createSanityClient(options);
export const imageBuilder = sanityImage(sanityClient);
