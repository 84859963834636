function sliceIntoChunks(arr, chunkSize) {
  const res = [];
  for (let i = 0; i < arr.length; i += chunkSize) {
    const chunk = arr.slice(i, i + chunkSize);
    res.push(chunk);
  }
  return res;
}

// see if an object is found in another array of objects
function hasObject(recs, vals) {
  if (!recs) return false;

  return recs.some(function (obj) {
    for (var x in obj) if (x in vals && obj[x] !== vals[x]) return false;
    return true;
  });
}

// convert cents to dollars, optional trailing zeros if round amount
function centsToPrice(cents, trailing = false) {
  const price = cents / 100;

  if (!trailing && price % 1 === 0) {
    return `${price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
  } else {
    const parts = price.toFixed(2).split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return `${parts.join(".")}`;
  }
}

// wrap incremental
function wrap(index, length) {
  if (index < 0) {
    index = length + (index % length);
  }
  if (index >= length) {
    return index % length;
  }
  return index;
}

// Find Tag in Array and output Human Name
function findTag(items, key, label, index) {
  const tag = items.filter((item) => item.includes(key))[index] || null;

  if (tag) {
    return tag.replace(key, `${label}: `);
  } else {
    return null;
  }
}

function convertToKebabCase(string) {
  return string.replace(/\s+/g, "-").toLowerCase();
}

function getParameterByName(name, url) {
  // eslint-disable-next-line no-useless-escape
  name = name.replace(/[\[\]]/g, "\\$&");
  var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
}

const Keys = {
  ENTER: 13,
  SPACE: 32,
  LEFT: 37,
  RIGHT: 39,
  UP: 38,
  DOWN: 40,
};

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}

// keep number counters within a range
function clampRange(value, min = 0, max = 1) {
  return value < min ? min : value > max ? max : value;
}

function variantGidToId(gid) {
  return gid.replace("gid://shopify/ProductVariant/", "");
}

function variantIdToGid(id) {
  return `gid://shopify/ProductVariant/${id}`;
}

const isBrowser = typeof window !== "undefined";

export {
  sliceIntoChunks,
  hasObject,
  centsToPrice,
  wrap,
  Keys,
  findTag,
  convertToKebabCase,
  getParameterByName,
  encode,
  clampRange,
  variantGidToId,
  variantIdToGid,
  isBrowser,
};
