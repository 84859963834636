exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blogPost.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-bundle-product-js": () => import("./../../../src/templates/bundleProduct.js" /* webpackChunkName: "component---src-templates-bundle-product-js" */),
  "component---src-templates-collection-js": () => import("./../../../src/templates/collection.js" /* webpackChunkName: "component---src-templates-collection-js" */),
  "component---src-templates-modular-page-js": () => import("./../../../src/templates/modularPage.js" /* webpackChunkName: "component---src-templates-modular-page-js" */),
  "component---src-templates-news-portal-js": () => import("./../../../src/templates/newsPortal.js" /* webpackChunkName: "component---src-templates-news-portal-js" */),
  "component---src-templates-product-js": () => import("./../../../src/templates/product.js" /* webpackChunkName: "component---src-templates-product-js" */),
  "component---src-templates-simple-page-js": () => import("./../../../src/templates/simplePage.js" /* webpackChunkName: "component---src-templates-simple-page-js" */)
}

