// @ts-ignore
import Client from "shopify-buy";
import { isBrowser } from "@lib/helpers";

// First, check that Shopify variables are set
const hasShopify = process.env.GATSBY_SHOPIFY_TOKEN;

// Warn the client if variables are missing
if (!hasShopify && isBrowser) {
  console.warn("Shopify .env variables are missing");
}

// Otherwise, setup the client and export
const options = {
  storefrontAccessToken: process.env.GATSBY_SHOPIFY_TOKEN,
  domain: process.env.GATSBY_CHECKOUT_DOMAIN,
};

export default hasShopify ? Client.buildClient(options) : null;
